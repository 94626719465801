import React from "react";
import styles from "./freezers.module.scss";
import {
  GET_COMPANY_ALERT_PARAMS,
  GET_COMPANY_OPES,
  GET_FREE_DEVICES,
  GET_REFRIGERATIONS,
  UPDATE_REFRIGERATION,
} from "../../../../queries";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  Checkbox,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import CreateFreezerModal from "./createFreezerModal";
import { useEffect } from "react";
import { language } from "../../../../const";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormattedMessage, useIntl } from "react-intl";

const MyFreezers = ({ company_id }) => {
  const [edit, setEdit] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOption, setSelectedOption] = useState("default");
  const [selectedAssignedTo, setSelectedAssignedTo] = useState("default");
  const [selectedTempRange, setSelectedTempRange] = useState("default");
  const [selectedType, setSelectedType] = useState("default");
  const [selectedStatus, setSelectedStatus] = useState("default");
  const [selectedResponsible, setSelectedResponsible] = useState([]);

  const [updatedValues, setUpdatedValues] = useState({
    name: null,
    temp_range: null,
    refrigeration_id: null,
    type: null,
    status: null,
  });

  const [freezerModal, setFreezerModal] = useState(false);

  // const {
  //    loading: refrigerationsLoading,
  //   data: refrigerationsData,
  // } = useLazyQuery(
  //   GET_REFRIGERATIONS,
  //   {
  //     variables: {
  //       assigned_to: company_id ? company_id : "",
  //     },
  //   },
  //   { fetchPolicy: "network-only" }
  // );

  const [
    getRefrigerations,
    {
      data: refrigerations,
      error: RrefrigerationsError,
      loading: refrigerationsLoading,
    },
  ] = useLazyQuery(
    GET_REFRIGERATIONS,
    {
      variables: {
        assigned_to: company_id ? company_id : "",
      },
    },
    { fetchPolicy: "network-only" }
  );
  let info = refrigerations?.refrigerations;

  //traigo temp range Y FREE_DEVICES
  const [
    getCompanyAlerts,
    {
      // loading: companyDetailLoading,
      error: companyDetailError,
      data: companyDetailData,
    },
  ] = useLazyQuery(GET_COMPANY_ALERT_PARAMS);

  const [
    getCompanyOpes,
    {
      // loading: companyDetailLoading,
      error: companyError,
      data: companyOpes,
    },
  ] = useLazyQuery(GET_COMPANY_OPES);

  const [
    getFreeDevices,
    {
      // loading: companyDetailLoading,
      error: freeDevicesError,
      data: freeDevices,
    },
  ] = useLazyQuery(GET_FREE_DEVICES);

  const [
    updateRefrigeration,
    {
      loading: updateRefrigerationLoading,
      error: updateREfrigerationLoadingError,
    },
  ] = useMutation(UPDATE_REFRIGERATION);

  useEffect(() => {
    const fetch = async () => {
      await getCompanyAlerts({
        variables: {
          compani_id: company_id,
        },
      });

      await getFreeDevices({
        variables: {
          company_id: company_id,
          device_format: "RC_Static",
        },
      });
      await getCompanyOpes({
        variables: {
          compani_id: company_id,
        },
      });
      await getRefrigerations();
    };
    fetch();
  }, [/* companyDetailData, */ freeDevices]);

  const handleEdit = (e, ref) => {
    setEdit(ref.refrigeration_id);
    setSelectedResponsible(ref.responsible);
  };
  const handleCancel = () => {
    setSelectedOption("default");
    setEdit(null);
    setUpdatedValues({
      name: null,
      assigned_to: null,
      temp_range: null,
      refrigeration_id: null,
      type: null,
    });
    setSelectedResponsible([]);
  };
  //opciones del select de asiigned_to
  const handleChange = (e, id) => {
    const { name, value } = e.target;
    if (name === "assigned_to") {
      setSelectedAssignedTo(value);
    } else if (name === "temp_range") {
      setSelectedTempRange(value);
    } else if (name === "type") {
      setSelectedType(value);
    } else if (name === "status") {
      setSelectedStatus(value);
    }

    setUpdatedValues({
      ...updatedValues,
      [name]: value,
      refrigeration_id: id,
    });
  };

  // handle update
  const handleUpdateFreezer = async (e) => {
    e.preventDefault();

    let responsible2;

    if (!selectedResponsible || selectedResponsible?.length === 0) {
      responsible2 = null;
    } else if (selectedResponsible?.length !== 0) {
      responsible2 = selectedResponsible;
    }
    // // mutation update refrigeration//
    try {
      if (
        window.confirm(
          intl.formatMessage({ id: "are_you_sure_confirmChanges" })
        )
      ) {
        await updateRefrigeration({
          variables: {
            // qr: updatedValues?.assigned_to,
            name: updatedValues?.name,
            temperature_range: updatedValues?.temp_range,
            refrigeration_id: updatedValues?.refrigeration_id,
            type: updatedValues?.type,
            status: updatedValues?.status,
            responsible: responsible2,
          },
          refetchQueries: [
            { query: GET_REFRIGERATIONS, variables: company_id },
          ],
        });
      }

      // window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(
        intl.formatMessage({ id: "device_using_temp" })
      );
    }
    setEdit(null);
    setUpdatedValues({
      name: null,
      assigned_to: null,
      temp_range: null,
      refrigeration_id: null,
      type: null,
    });
    setSelectedResponsible([]);
  };
  //Modal handlers
  const openFreezerModal = () => {
    setFreezerModal(true);
  };
  const closeCreateFreezerModal = () => {
    setFreezerModal(false);
  };

  //handler de la paginacion
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  //PARA PAGINAR -------------------------------------------------------------------------
  let indexOfLastElement = page * rowsPerPage;
  let indexOfFirstElement = indexOfLastElement - rowsPerPage;
  let currentElements = refrigerations?.refrigerations?.slice(
    indexOfFirstElement,
    indexOfLastElement
  );

  const handleChangeResponsible = (event) => {
    setSelectedResponsible(event.target.value);
  };
  const intl = useIntl();
  if (updateRefrigerationLoading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div className={styles.freezersContainer}>
        <ToastContainer />
        {/* si refrigerations data no es undefined y hay data en el array, muestro la tabla */}
        {refrigerations?.refrigerations?.length !==
        0 /* && refrigerationsData */ ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* HEAD NAME */}
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                        // fontFamily: "'Quattrocento Sans', sans-serif",
                        width: "20%",
                      }}
                    >
                      {language === "es" ? "NOMBRE" : "NAME"}
                    </TableCell>
                    {/*Type */}
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                        //fontFamily: "'Quattrocento Sans', sans-serif",
                        width: "17%",
                      }}
                    >
                      {language === "es" ? "TIPO" : "TYPE"}
                    </TableCell>

                    {/* HEAD ASSIGNED TO QR */}
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                        //fontFamily: "'Quattrocento Sans', sans-serif",
                        width: "17%",
                      }}
                    >
                      {language === "es" ? "ASIGNADO AL QR" : "ASSIGNED TO QR"}
                    </TableCell>

                    {/* Head temperature range-------- */}
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                        //fontFamily: "'Quattrocento Sans', sans-serif",
                        width: "10%",
                      }}
                    >
                      {language === "es" ? "RANGO TEMP." : "TEMP. RANGE"}
                    </TableCell>

                    {/* HEAD Status */}
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                        // fontFamily: "'Quattrocento Sans', sans-serif",
                        width: "17%",
                      }}
                    >
                      {language === "es" ? "ESTADO" : "STATUS"}
                    </TableCell>
                    {/* HEAD RESPONSIBLE */}
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                        // fontFamily: "'Quattrocento Sans', sans-serif",
                        width: "7%",
                      }}
                    >
                      {language === "es" ? "RESPONSABLE" : "RESPONSIBLE"}
                    </TableCell>

                    {/* BUTTON EDIT */}
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                        //
                        color: "#00ABC8",
                        //fontFamily: "'Quattrocento Sans', sans-serif",
                        width: "10%",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentElements?.map((ref, index) => {
                    return (
                      <TableRow key={index + 4564654}>
                        {/* ---------NAME----------------- */}
                        <TableCell
                          //key={ref?._id}
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(ref) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //
                            // fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "17%",
                          }}
                        >
                          {edit === ref?.refrigeration_id ? (
                            <input
                              type="text"
                              name="name"
                              onChange={(e) =>
                                handleChange(e, ref?.refrigeration_id)
                              }
                              defaultValue={ref?.name}
                              className={styles.inputName}
                            />
                          ) : (
                            <span className={styles.data}>{ref?.name}</span>
                          )}
                        </TableCell>
                        {/* -------------TYPE----------------- */}
                        <TableCell
                          // key={ref?._id}
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(ref) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //
                            //fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "17%",
                          }}
                        >
                          {edit === ref?.refrigeration_id ? (
                            <input
                              type="text"
                              name="type"
                              onChange={(e) =>
                                handleChange(e, ref?.refrigeration_id)
                              }
                              defaultValue={ref?.type}
                              className={styles.inputName}
                            />
                          ) : (
                            <span className={styles.data}>{ref?.type}</span>
                          )}
                        </TableCell>
                        {/* //---------QR--------------------// */}
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(ref) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //
                            //fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "17%",
                          }}
                        >
                          <span className={styles.data}>{ref?.qr}</span>
                        </TableCell>

                        {/* TEMP RANGE */}
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(ref) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //
                            //fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "10%",
                          }}
                        >
                          {edit === ref?.refrigeration_id ? (
                            <select
                              name="temp_range"
                              value={selectedTempRange}
                              onChange={(e) =>
                                handleChange(e, ref?.refrigeration_id)
                              }
                              className={styles.temp_range_Select}
                            >
                              <option value={ref?.temperature_range?.name}>
                                {ref?.temperature_range?.name}
                              </option>
                              {/* en el .map repite el temp_range.name actual, x eso lo filtro */}
                              {companyDetailData?.company?.alert_params?.temperature_alerts
                                ?.filter(
                                  (range) =>
                                    range.name !== ref?.temperature_range?.name
                                )
                                .map((range, index) => (
                                  <option
                                    value={range?.name}
                                    key={index + 0.12}
                                  >
                                    {range?.name}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            ref?.temperature_range?.name
                          )}
                        </TableCell>

                        {/* status */}
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(ref) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //
                            //fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "17%",
                          }}
                        >
                          {/* status editable */}

                          {edit === ref?.refrigeration_id ? (
                            <select
                              name="status"
                              defaultValue={ref?.status}
                              onChange={(e) =>
                                handleChange(e, ref?.refrigeration_id)
                              }
                              className={styles.temp_range_Select}
                            >
                              <option value="ACTIVE">
                                {intl.formatMessage({ id: "active" })}
                              </option>
                              <option value="INACTIVE">
                                {intl.formatMessage({ id: "inactive" })}
                              </option>
                            </select>
                          ) : (
                            <>
                              {ref?.status === "INACTIVE"
                                ? intl.formatMessage({ id: "inactive" })
                                : intl.formatMessage({ id: "active" })}
                            </>
                          )}
                        </TableCell>

                        {/* responsible */}

                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(ref) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //
                            //fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "7%",
                          }}
                        >
                          {edit === ref?.refrigeration_id ? (
                            <>
                              <Select
                                style={{ height: "2vw", width: "10vw" }}
                                multiple
                                // sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                value={selectedResponsible || []}
                                onChange={(e) => {
                                  handleChangeResponsible(e);
                                  handleChange(e, ref?.refrigeration_id);
                                }}
                                // renderValue={() => null}
                                renderValue={() =>
                                  language === "es"
                                    ? "Seleccionar..."
                                    : "Select..."
                                }
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                  getContentAnchorEl: null,
                                  PaperProps: {
                                    style: {
                                      maxHeight: 550, // Altura máxima del menú
                                    },
                                  },
                                }}
                              >
                                {companyOpes?.company?.operators
                                  ?.filter((ope) => ope.status === "ACTIVE")
                                  .map((ope, i) => (
                                    <MenuItem
                                      key={i + 0.25}
                                      value={ope.operator_id}
                                    >
                                      <Checkbox
                                        checked={selectedResponsible?.includes(
                                          ope?.operator_id
                                        )}
                                      />
                                      {ope?.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </>
                          ) : ref?.responsible?.length === 0 ||
                            !ref.responsible ? (
                            "--"
                          ) : (
                            <Select
                              multiple
                              value={[]}
                              style={{
                                height: "2vw",
                                width: "2vw",
                                border: "none",
                              }}
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "center",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                  style: {
                                    maxHeight: 550, // Altura máxima del menú
                                  },
                                },
                              }}
                            >
                              {ref?.responsible?.map((r, i) => (
                                <MenuItem value={""} key={i + 0.33}>
                                  {companyOpes?.company?.operators.find(
                                    (ope) => {
                                      return ope.operator_id === r;
                                    }
                                  )?.name + ` (#${r.split("-")[1]})`}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </TableCell>
                        {/* edit button */}
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(ref) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",

                            //fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "10%",
                          }}
                        >
                          {edit === ref?.refrigeration_id ? (
                            <div className={styles.buttonsContainer}>
                              <button
                                onClick={handleUpdateFreezer}
                                className={styles.refButton}
                              >
                                {language === "es" ? "Guardar" : "Save"}
                              </button>

                              <button
                                onClick={handleCancel}
                                className={styles.refButton}
                                style={{
                                  color: "#D60707",
                                  borderColor: "#D60707",
                                }}
                              >
                                {language === "es" ? "Cancelar" : "Cancel"}
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={(e) => handleEdit(e, ref)}
                              className={styles.refButton}
                            >
                              {language === "es" ? "Editar" : "Edit"}
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <button
              onClick={openFreezerModal}
              className={styles.createFreezerButton}
            >
              {localStorage.getItem("language") === "es"
                ? "Nueva heladera"
                : "New Refrigerator"}
            </button>
            <CreateFreezerModal
              freezerModal={freezerModal}
              closeCreateFreezerModal={closeCreateFreezerModal}
              companyDetailData={companyDetailData}
              freeDevices={freeDevices?.freeDevices}
            />

            {/* PAGINACION ---------------------------------------------------------------------------------------- */}
            {/* {devicesData?.devices?.length > 10 ? ( */}
            {info?.length > 10 ? (
              <div className={styles.pagination}>
                <Pagination
                  count={Math.ceil(info?.length / rowsPerPage)}
                  page={page}
                  onChange={handleChangePage}
                  size={window.screen.width <= 1000 ? "small" : "large"}
                />
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <button
              onClick={openFreezerModal}
              className={styles.createFreezerButton}
            >
              {localStorage.getItem("language") === "es"
                ? "Nueva heladera"
                : "New Refrigerator"}
            </button>
            <CreateFreezerModal
              freezerModal={freezerModal}
              closeCreateFreezerModal={closeCreateFreezerModal}
              companyDetailData={companyDetailData}
              freeDevices={freeDevices?.freeDevices}
            />
          </>
        )}
      </div>
      // 'freezers'
    );
  }
};

export default MyFreezers;
