import React, { useContext, useState } from "react";
import styles from "./freezerStyles.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
  Pagination,
} from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core/styles";
import DropdownData from "../Reports/dropdownData/DropdownData";
import { GET_REFRIGERATIONS } from "../../queries";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { ToastContainer } from "react-toastify";

import wifi from "./Iconos_Alerta Conectividad.png";
import tempIcon from "./Iconos_Alerta_Temperatura.png";
import noAlert from "./Iconos_Static_No Alerta.png";
import wifi_rojo from "./Icono_Wifi_Rojo.png";
import wifi_amarillo from "./Icono_Wifi_Amarillo.png";
import wifi_verde from "./Icono_Wifi_Verde.png";

import FreezerAlertsModal from "./freezerAlertsModal/FreezerAlertsModal";

import { convertirHoraLocal, expiredAction } from "../../helpers";
import TestingTable from "./reactDataTable/TestingTable";
import { AlertsContext } from "../../context/alerts/AlertsProvider";
import { GoDotFill } from "react-icons/go";

const RC_Static = ({ gmt }) => {
  const { alerts, setAlerts, viewed } = useContext(AlertsContext);

  const intl = useIntl();
  const fromTestingTable = (device) => {
    console.log(device);
  };

  const [open, setOpen] = useState(false);
  const [refrigerations, setRefrigerations] = useState([]);
  const [searchVariables, setSearchVariables] = useState({
    qr: "",
    name: "",
  });
  const [selectedRefrigeration, setSelectedRefrigeration] = useState(null);
  const [refetch, setRefetch] = useState(false);

  //estados para la paginacion
  const [dataLength, setDataLength] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [searched, setSearched] = useState(false);
  const [buttonError, setButtonError] = useState(false);

  const useStyles = makeStyles((theme) => ({
    customAccordionSummary: {
      padding: 0,
      margin: 0,
    },
  }));
  const classes = useStyles();

  const [expanded, setExpanded] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  //estado para el input de busqueda por id
  const [searchInput, setSearchInput] = useState("");
  //-----------------------------//
  const [
    getRefrigerations,
    {
      loading: refrigerationsLoading,
      error: refrigerationsError,
      data: refrigerationsResult,
    },
  ] = useLazyQuery(GET_REFRIGERATIONS, {
    fetchPolicy: "no-cache",
  });
  const refetch_From_update_alert_modal = () => {
    setRefetch(!refetch);
  };

  //hasta q haya subscriptions hago refetch cada 15 min
  useEffect(() => {
    expiredAction();
    const fetch = async () => {
      try {
        const response = await getRefrigerations({
          variables: {
            page: page,
            per_page: rowsPerPage,
          },
        });

        setRefrigerations(response?.data?.refrigerations);

        // Filtra las refrigerations con status "ACTIVE"
        const activeRefrigerations = response?.data?.refrigerations?.filter(
          (refrigeration) => refrigeration?.status === "ACTIVE"
        );

        setDataLength(activeRefrigerations?.length);
        // console.log(response?.data?.refrigerations)
      } catch (error) {
        console.error(error);
      }
    };
    fetch();
  }, [getRefrigerations, refetch, page, rowsPerPage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch_From_update_alert_modal();
    }, 5 * 60 * 1000); // 15 minutos en milisegundos

    // Limpieza del intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  });

  //-----------------------------//
  const handleChange = (e) => {
    setSearchVariables({ ...searchVariables, [e.target.name]: e.target.value });
  };
  let result = null;

  const handleFilter2 = async (e) => {
    //hay q modificar el back para recibir qr y name
    e.preventDefault();
    result = null;

    const fetch = async () => {
      try {
        result = await getRefrigerations({
          variables: {
            qr: searchVariables?.qr,
            name: searchVariables?.name,
          },
        });

        setRefrigerations(result?.data?.refrigerations);
        // setSearchVariables({ qr: "", type: "" });
        setDataLength(result?.data?.refrigerations?.length);
        setSearched(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  };

  const handleChangePage2 = (event, value) => {
    setPage(value);
    setExpanded(Array(dataLength).fill(false)); // Resetea la expansión de los acordeones
  };

  const handleChangeAccordion = (index) => (event, isExpanded) => {
    // Verificar si el evento proviene del input date
    const isDateInput = event.target.type === "date";

    if (!isDateInput) {
      setExpanded((prevExpanded) => {
        const newExpanded = [...prevExpanded];
        newExpanded[index] = isExpanded;
        return newExpanded;
      });
    }
    if (isExpanded) {
      setTimeout(() => {
        const accordionElement = document.getElementById(`accordion-${index}`);
        if (accordionElement) {
          const accordionRect = accordionElement.getBoundingClientRect();
          //const accordionHeight = window.innerWidth * 0.45; // Calcula la altura en píxeles de 45vw
          const scrollOffset =
            accordionRect.bottom - window.innerHeight; /* + accordionHeight  */

          window.scrollBy({
            top: scrollOffset,
            behavior: "smooth",
          });
        }
      }, 500); // Ajusta el retraso si es necesario
    }
  };

  //--handler modal de alertas--//
  const alertsModal = (e, selecetedRefrigeration) => {
    e.stopPropagation();
    setSelectedRefrigeration(selecetedRefrigeration);
    handleOpen();
  };

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRefrigeration(null);
  };
  //wifi signal colors
  const wifiSignal = (signal) => {
    console.log(signal);
    if (signal === undefined || signal === null) {
      return "";
    }
    if (signal >= 0 || signal < -100) {
      return "";
    } else if (signal >= -65) {
      return <img src={wifi_verde} alt="" className={styles.wifi_icon} />; // Buena señal
    } else if (signal >= -80) {
      return <img src={wifi_amarillo} alt="" className={styles.wifi_icon} />;
    } else {
      return <img src={wifi_rojo} alt="" className={styles.wifi_icon} />;
    }
  };

  const currentElements = refrigerations
    ?.filter((e) => e.status === "ACTIVE") // Filtrar solo las refrigerations activas
    ?.slice((page - 1) * rowsPerPage, page * rowsPerPage); // Aplicar paginación

  const tabla = "static 1"; /* 'static 2' */
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: "18px", // Tamaño de fuente deseado
    },
  }))(Tooltip);

  const getAlertIcon = (data) => {
    // Verifica si hay alertas y si todas están resueltas
    console.log(data);
    const hasAlerts = data?.alerts?.length > 0;
    const allAlertsResolved = data?.alerts?.every((al) => al?.solved === true);

    if (!hasAlerts || allAlertsResolved) {
      // No hay alertas o todas están resueltas
      return (
        <img
          src={noAlert}
          alt="No alerts"
          className={styles.icon}
          style={{ position: "relative", left: "3%" }}
        />
      );
    }

    // Mostrar iconos basados en el tipo de alerta
    return (
      <div>
        {data?.alerts?.some((alert) => alert?.type === "temperature") && (
          <img
            src={tempIcon}
            alt="Temperature alert"
            className={styles.icon}
            style={{ position: "relative", left: "5%" }}
          />
        )}
        {data?.alerts?.some((alert) => alert?.type === "disconnection") && (
          <img
            src={wifi}
            alt="Disconnection alert"
            className={styles.icon}
            style={{ position: "relative", left: "5%" }}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {/* BUSCADOR------------------------------------------------------------------ */}
      <div className={styles.pickerContainer}></div>

      <h3 className={styles.title}>
        <FormattedMessage id="reports_freeze">RC_Static</FormattedMessage>
      </h3>
      {/* FILTRADOS */}
      <div className={styles.formContainer}>
        <form className={styles.filter} onSubmit={handleFilter2}>
          <div className={styles.filterContainer}>
            {/* BUSQUEDA X QR ----------------------------------------------------------------- */}
            <input
              className={styles.select_qr}
              onChange={handleChange}
              type="text"
              name="qr"
              placeholder="QR"
            />
            {/* BUSQUEDA X NOMBRE */}
            <input
              className={styles.select_qr}
              onChange={handleChange}
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: "name" }) /* "Nombre" */}
            />
          </div>

          <div className={styles.searchButtonContainer}>
            <button
              type="submit"
              className={styles.searchButton}
              onClick={handleFilter2}
            >
              <FormattedMessage id="search_button">Search</FormattedMessage>
            </button>
          </div>
        </form>
      </div>

      {/* TABLA */}
      {tabla === "static 1" ? (
        <>
          {currentElements &&
          [...currentElements?.filter((e) => e.status === "ACTIVE")].length !==
            0 ? (
            <div className={styles.tableContainer}>
              <div className={styles.headerContainer}>
                <div
                  className={styles.header_item_name}
                  style={{ width: "23%" /*  border: "solid red 1px" */ }}
                >
                  {intl.formatMessage({ id: "name" })}
                </div>
                <div
                  className={styles.header_item}
                  style={{
                    width: "10%",
                    textAlign: "center",
                    /*  border: "solid red 1px", */
                  }}
                >
                  QR
                </div>
                {/* -------------------LAST READINGS----------------- */}
                <div
                  className={styles.header_item}
                  style={{
                    width: "10%",
                    textAlign: "center",
                    /* border: "solid red 1px", */
                  }}
                >
                  {intl.formatMessage({ id: "date_static_header" })}
                </div>
                <div
                  className={styles.header_item}
                  style={{
                    width: "10%",
                    textAlign: "center",
                    /* border: "solid red 1px", */
                  }}
                >
                  {intl.formatMessage({ id: "time_static_header" })}
                </div>
                <div
                  className={styles.header_item}
                  style={{
                    width: "10%",
                    textAlign: "center",
                    /* border: "solid red 1px", */
                  }}
                >
                  {intl.formatMessage({ id: "value_static_header" })}
                </div>

                <div
                  className={styles.header_item}
                  style={{
                    width: "10%",
                    textAlign: "center",
                    /*  border: "solid red 1px", */
                  }}
                >
                  {intl.formatMessage({ id: "alerts_static_header" })}
                </div>

                <div
                  className={styles.header_item}
                  style={{
                    width: "10%",
                    textAlign: "center",
                    /*  border: "solid red 1px", */
                  }}
                >
                  WIFI
                </div>
              </div>
              {[...currentElements?.filter((e) => e.status === "ACTIVE")].map(
                (f, index) => (
                  <Accordion
                    disableGutters={true}
                    sx={{
                      width: "97%",
                      position: "relative",
                    }}
                    style={
                      [
                        ...currentElements?.filter(
                          (e) => e.status === "ACTIVE"
                        ),
                      ].indexOf(f) %
                        2 ===
                      0
                        ? { background: "var(--white)" }
                        : { background: "#fafafa" }
                    }
                    key={index}
                    expanded={expanded[index]}
                    onChange={handleChangeAccordion(index)}
                    id={`accordion-${index}`}
                  >
                    <AccordionSummary
                      className={`${classes.customAccordionSummary} MuiAccordionSummary-root MuiAccordionSummary-gutters css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root`}
                    >
                      <div className={styles.dataContainer}>
                        {/* NAME */}
                        <div
                          className={
                            alerts?.static_id?.includes(f?.refrigeration_id)
                              ? styles.data_name_icon
                              : styles.data_name
                            //si hay alertas nuevas cambio el estilo para corregir el padding cuando se suma la bolita naranja
                          }
                          style={{ width: "23%" }}
                        >
                          {alerts?.static_id?.includes(f?.refrigeration_id) && (
                            <GoDotFill style={{ color: "var(--orange)" }} />
                            //pelotita de alerta
                          )}

                          {f?.name}
                        </div>
                        {/* qr--------- */}
                        <div className={styles.data} style={{ width: "10%" }}>
                          {f?.qr}
                        </div>
                        {/* ----------------last reading------------------- */}

                        {/* date --------------------------------------------- */}
                        <div className={styles.data} style={{ width: "10%" }}>
                          {!f?.last_reading ? (
                            "--"
                          ) : (
                            <>
                              {convertirHoraLocal(f?.last_reading?.time, gmt)
                                ?.split(" ")

                                .slice(0, 2)
                                .join(" ")}
                            </>
                          )}
                        </div>
                        {/*  time --------------------------------------------*/}
                        <div className={styles.data} style={{ width: "10%" }}>
                          {!f?.last_reading ? (
                            "--"
                          ) : (
                            <>
                              {convertirHoraLocal(f?.last_reading?.time, gmt)
                                ?.split(" ")

                                .slice(2, 6)
                                .join(" ")}
                            </>
                          )}
                        </div>
                        {/* value---------------------------------------------- */}
                        <div className={styles.data} style={{ width: "10%" }}>
                          {f?.last_reading?.cv
                            ? f?.last_reading?.cv?.toFixed(2)
                            : f?.last_reading?.temp
                            ? `${f?.last_reading?.temp.toFixed(2)}°C`
                            : "--"}
                        </div>

                        {/* alerts--------------------------------------------*/}
                        <div
                          className={styles.data_status}
                          style={{ width: "10%" }}
                          onClick={(e) => alertsModal(e, f)}
                        >
                          <div className={styles.icons_container}>
                            {/* Mostrar icono basado en el estado de las alertas */}
                            {getAlertIcon(f)}
                          </div>
                        </div>
                        {/* wifi --------------------------*/}
                        <div
                          className={styles.data_wifi}
                          style={{ width: "10%" }}
                        >
                          {f?.wifi_signal === undefined ||
                          f?.wifi_signal === null ||
                          f.wifi_signal === 0 ? (
                            ""
                          ) : (
                            <CustomTooltip title={f?.wifi_signal}>
                              {wifiSignal(f?.wifi_signal)}
                            </CustomTooltip>
                          )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.accordionDetails}
                      sx={{
                        height: "35vw",
                        width: "97%",
                      }}
                    >
                      <DropdownData
                        expanded={expanded}
                        index={index}
                        rc_freeze={true}
                        refrigeration_id={f?.refrigeration_id}
                        ref_id={f?._id}
                        qr={f?.qr}
                        name={f?.name}
                        freezer_temp_range={f?.temperature_range}
                        // getInterval={getInterval}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </div>
          ) : (
            <h1 className={styles.searchParamstext}>
              {searched && (
                <FormattedMessage id="no_results">
                  No results found
                </FormattedMessage>
              )}
              {!searched && (
                <FormattedMessage id="initial_message">
                  Please select searching settings
                </FormattedMessage>
              )}
            </h1>
          )}
          {/* ------pagination--------- */}

          {/* mostrar paginacion cuando sea necesario */}
          {dataLength > rowsPerPage && (
            <Pagination
              count={Math.ceil(dataLength / rowsPerPage)}
              page={page}
              onChange={handleChangePage2}
              style={{
                display: "flex",
                width: "94%",
                justifyContent: "end",
                margin: "0 auto",
              }}
            />
          )}
        </>
      ) : (
        <TestingTable fromTestingTable={fromTestingTable} />
      )}

      {/* ---------Alerts Modal---------- */}

      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "80vw",
            height: "42vw",
            padding: "20px",
            borderRadius: "8px",
            // border: "1px solid red",
          }}
        >
          <FreezerAlertsModal
            freezer={selectedRefrigeration}
            open={open}
            refetch={refetch_From_update_alert_modal}
          />
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default RC_Static;
